import { SetupContext } from '@vue/composition-api'
import Vue from 'vue'
import axios from 'axios'
import { isGuestPage } from 'src/hooks/routeHook'
import { notifyError1 } from './notificationHook'

export function setupAxios(context: SetupContext, loginHref: string = '/login'): void {
  const root = context.root as Vue
  // base url
  axios.defaults.baseURL = '/api/v3'

  // token
  const token = root.$store.getters['localStorage/serverToken']
  if (token) {
    axios.defaults.headers['Authorization'] = `Bearer ${token}`
  }

  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      let st = 400
      if (error.response) {
        st = error.response.status
      }
      if (st === 401 && !isGuestPage(root.$router.currentRoute.name)) {
        // go to login if 401 is returned on response
        root.$router.push('/login')
      } else if (st === 503) {
        // メンテナンス時はALBで503とともに静的なhtmlを返すようにする.
        // どこでもいいので一旦リロードすればブラウザにそのhtmlが表示される.
        window.location.href = '/maintenance'
      } else if (st === 504) {
        notifyError1(root, 'タイムアウトになり操作が失敗しました。管理者に連絡してください。')
      }
      return Promise.reject(error)
    }
  )
}
